




































import { Observer } from 'mobx-vue'
import { Component, Provide, Vue, Watch } from 'vue-property-decorator'
import { NftDetailViewModel } from '@/modules//reward/viewmodel/nft-detail-viewmodel'

@Observer
@Component({
  components: {
    'popo-info': () => import('@/modules/popo/components/details/popo-info.vue'),
    'decorate-more': () => import('@/modules/reward/components/popo/decorate-more.vue'),
    'attach-nft-section': () => import('@/modules/popo/components/details/attach-nft-section.vue'),
    'popo-activity-history': () => import('@/modules/popo/components/details/popo-activity-history.vue'),
  },
})
export default class extends Vue {
  @Provide() vm = new NftDetailViewModel()
  @Watch('$route.params.id', { immediate: true }) idChange(val) {
    if (val) this.vm.changeDecoratedPopoId(val)
  }

  destroyed() {
    this.vm.destroy()
  }
}
