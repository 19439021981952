import { DecoratedPopoModel } from '@/models/decorated-popo-model'
import { popoStore } from '@/stores/popo-store'
import { action, IReactionDisposer, observable, reaction, when } from 'mobx'

export class NftDetailViewModel {
  @observable decoratedPopoId?: string = undefined
  @observable decoratedPopoDetail?: DecoratedPopoModel = undefined
  @observable decoratedPopoInfo: any = undefined
  @observable fetchDecoratedPopoDetailLoading = false
  @observable loaded = false

  private _disposers: IReactionDisposer[]

  constructor() {
    this._disposers = [
      reaction(
        () => this.decoratedPopoId,
        async (decoratedPopoId) => {
          if (!decoratedPopoId) {
            this.decoratedPopoDetail = undefined
            return
          }
          await when(() => popoStore.loadedDecoratedPopos)
          this.fetchDecoratedPopoDetail()
        }
      ),
      reaction(
        () => popoStore.decoratedPoPos,
        async (decoratedPoPos: any) => {
          if (!decoratedPoPos?.length || !this.decoratedPopoId) {
            this.decoratedPopoDetail = undefined
            return
          }
          this.fetchDecoratedPopoDetail()
        }
      ),
    ]
  }

  destroy() {
    this._disposers.forEach((d) => d())
  }

  @action changeDecoratedPopoId(id) {
    this.decoratedPopoId = id
  }

  @action fetchDecoratedPopoDetail() {
    this.decoratedPopoDetail = popoStore?.decoratedPoPos?.find((item) => item.id === this.decoratedPopoId)
    if (!this.decoratedPopoDetail) return
    this.decoratedPopoInfo = popoStore?.buildDecoratedPopoInfo(this.decoratedPopoDetail)
    this.loaded = true
  }
}
